/* eslint-disable no-jquery/no-browser */

import jQuery from 'jquery'

import browser from 'jquery-browser-plugin'

import 'jquery-ui-bundle/jquery-ui.js'
import './jquery_ext.js'
import 'jquery-ujs'
import './compatibility.jquery.js'
import { sanitizeUrl } from '@/shared/utils'

global.$ = global.jQuery = jQuery
jQuery.browser = browser

export default jQuery

jQuery.ajaxPrefilter(options => {
  if (options.url) {
    options.url = sanitizeUrl(options.url)
  }
})

// revert to old (insecure) behavior for $.html(), see https://jquery.com/upgrade-guide/3.5/
// without this, $("body").html("<div /><span />") produces a span inside a div.
var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi // eslint-disable-line

jQuery.htmlPrefilter = function (html) {
  /**
   * $.htmlPrefilter() is called when jQuery is used to modify the DOM, so it's a convenient way to hook into RJS madness, and
   * automatically re-render React components as necessary.
   */
  window.dispatchEvent(new CustomEvent('htmlPrefilter'))

  return html.replace(rxhtmlTag, "<$1></$2>")
}
