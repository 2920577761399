/* eslint-disable no-jquery/no-jquery-constructor, no-jquery/no-find-collection, no-jquery/no-other-methods, no-jquery/no-attr, no-jquery/no-html */

/**
 * A lighter version of embeddedComponentStore.tsx that does not use MobX or MUI and so lacks some bells and whistles
 * but has fewer dependencies. Used on the login page.
 */
import React from 'react';
import { createEmbeddedComponentStore } from './embeddedComponentStoreFactory';

export const embeddedComponentStore = createEmbeddedComponentStore(
  (ReactComponentClass, data, contentHtml) => {
    return <ReactComponentClass {...data}>
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} />}
    </ReactComponentClass>;
  });

embeddedComponentStore.init();
