window.onerror = function (message, url, line, columnNumber, errorObject) {
  'use strict'

  // If there is more than one then we see the last one.  If you care about more than one then make this smarter.
  var errorKey = "CDD_JS_ERROR_KEY"

  var error = {
    baseLocation: window.location.href,
    message: message,
    jsLocation: url,
    lineNumber: line,
    columnNumber: columnNumber,
    stack: (errorObject ? errorObject.stack : []),
  }

  window.localStorage.setItem(errorKey, JSON.stringify(error))
}

window.getErrors = function () {
  'use strict'

  // Ignore logging messages
  var errorKey = "CDD_JS_ERROR_KEY"

  if (window.localStorage[errorKey]) {
    var errors = JSON.stringify({ errors: window.localStorage[errorKey] })
    window.localStorage.removeItem(errorKey)
    return errors
  } else {
    return null
  }
}

const CDD = window.CDD = window.CDD || {}
if (!CDD.Terminology) {
  // Terminology gets overwritten in base.html.haml, but there seem to be cases in which static Javascript functions are
  // called first so initialize it.
  const terminologElem = document.getElementById("terminology-json")
  const dictionary = terminologElem ? JSON.parse(document.getElementById("terminology-json").dataset.terminology) : {}
  CDD.Terminology = {
    dictionary,
    t: function (term) {
      'use strict'
      return this.dictionary[term] || term
    },
  }
}

export const Terminology = window.CDD.Terminology

window.keys = function (obj) {
  'use strict'

  var results = []
  for (var propertyName in obj) {
    results.push(propertyName)
  }
  return results
}

if (navigator.userAgent.includes("Firefox")) {
  // on Firefox, prevent any non-numeric characters from being entered into number inputs
  document.addEventListener('keydown', function (e) {
    const { target, key } = e
    if (target.tagName === 'INPUT' && target.type === 'number') {
      // Allow control characters
      if (
        ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Tab'].includes(key) ||
          ((e.ctrlKey || e.metaKey) && ['a', 'c', 'v', 'x'].includes(key))
      ) {
        return
      }

      // Allow numbers
      if (key >= '0' && key <= '9') {
        return
      }

      // and other symbols
      if ([',', '.', '-', 'e', 'E'].includes(key)) {
        return
      }

      // Prevent other characters
      e.preventDefault()
    }
  }, true)
}
